export default defineNuxtRouteMiddleware(async (to, from) => {
    
    const { $parseJwt, $isTokenExpired, $get } = useNuxtApp()
    const user = useUser();
    const company = useCompany();
    
    // All unauthorized users are redirected to login
    const token = sessionStorage.getItem('token')
    
    if ($isTokenExpired(token)) {
        return await navigateTo("/login");
    }


    if (token == null){
        if(to.name !== 'login')
            return await navigateTo("/login");
    }

    const invitation_id = sessionStorage.getItem('invitation_id');
    if (invitation_id != null){
        if(to.name !== 'invitations')
            return await navigateTo("/invitations");
    }


    const decodedToken = $parseJwt(token);

   

    user.value.firstname = decodedToken.firstname;
    user.value.lastname = decodedToken.lastname;
    user.value.email = decodedToken.email;

    if ( (!("firstname" in decodedToken)) && (!("firstname" in decodedToken)) ) {
        return await navigateTo("/user/profile");
    }

    if ("avatar" in decodedToken ){
        user.value.avatar = decodedToken.avatar;
    }
    const account_id = localStorage.getItem('account_id');
    if (decodedToken.membership.length > 0){
        
        if (decodedToken.membership.length == 1){
            company.value.name = decodedToken.membership[0].name;
            company.value.notification_server_id = decodedToken.membership[0].notification_server_id;
            company.value.country = decodedToken.membership[0].country;
            company.value.role = decodedToken.membership[0].role;
            company.value.mode = decodedToken.membership[0].mode;
            company.value.account_user_id = decodedToken.membership[0].account_user_id;
        }
  
    }
})